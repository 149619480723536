import { beforeMain } from "@popperjs/core"

export default [
    {
        path: "/",
        redirect: "/residences",
    },
    {
        path: "/residences",
        name: "Residences",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_KEY",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_APP",
                "G_APP_NOM",
                "G_KEY_MOVE",
                "G_NAME_MOVE",
                "G_RESIDENCES",
                "G_KIBOLT",
                "G_TAGIT",
                "G_CONNECTIT",
                "G_CODE_TEL",
                "G_CODE_CLAV",
            ],
            tab: "residences",
        },
        component: () => import("@/views/residence/List.vue"),
    },
    {
        path: "/residence/:id(\\d+)/:tab(details|authorizations|codes|central-units|subaccounts)?/:page_num?",
        name: "Residence",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_KEY",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_APP",
                "G_APP_NOM",
                "G_KEY_MOVE",
                "G_NAME_MOVE",
                "G_RESIDENCES",
                "G_KIBOLT",
                "G_TAGIT",
                "G_CONNECTIT",
                "G_CODE_TEL",
                "G_CODE_CLAV",
            ],
            entity: "residence",
            tab: "residences",
        },
        component: () => import("@/views/residence/Details.vue"),
    },
    {
        path: "/residence/:id(\\d+)/export_fobs",
        name: "ExportFobsResidence",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_KEY",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_APP",
                "G_APP_NOM",
                "G_KEY_MOVE",
                "G_NAME_MOVE",
                "G_RESIDENCES",
                "G_KIBOLT",
                "G_TAGIT",
                "G_CONNECTIT",
                "G_CODE_TEL",
                "G_CODE_CLAV",
            ],
            privileges: ["LAST_KEY"],
            entity: "residence",
            tab: "residences",
        },
        component: () => import("@/views/residence/ExportFobsView.vue"),
    },
    {
        path: "/stairwell/:id(\\d+)/:tab(details|authorizations|codes|central-units|subaccounts)?/:page_num?",
        name: "Stairwell",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_KEY",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_APP",
                "G_APP_NOM",
                "G_KEY_MOVE",
                "G_NAME_MOVE",
                "G_RESIDENCES",
                "G_KIBOLT",
                "G_TAGIT",
                "G_CONNECTIT",
                "G_CODE_TEL",
                "G_CODE_CLAV",
            ],
            entity: "stairwell",
            tab: "stairwells",
        },
        component: () => import("@/views/stairwell/Details.vue"),
    },
    {
        path: "/stairwell/:id(\\d+)/export_fobs",
        name: "ExportFobsStairwell",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_KEY",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_APP",
                "G_APP_NOM",
                "G_KEY_MOVE",
                "G_NAME_MOVE",
                "G_RESIDENCES",
                "G_KIBOLT",
                "G_TAGIT",
                "G_CONNECTIT",
                "G_CODE_TEL",
                "G_CODE_CLAV",
            ],
            privileges: ["LAST_KEY"],
            entity: "residence",
            tab: "residence",
        },
        component: () => import("@/views/residence/ExportFobsView.vue"),
    },
    {
        path: "/building/:id(\\d+)/:tab(details|authorizations|codes|central-units|subaccounts)?/:page_num?",
        name: "Building",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_KEY",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_APP",
                "G_APP_NOM",
                "G_KEY_MOVE",
                "G_NAME_MOVE",
                "G_RESIDENCES",
                "G_KIBOLT",
                "G_TAGIT",
                "G_CONNECTIT",
                "G_CODE_TEL",
                "G_CODE_CLAV",
            ],
            entity: "building",
            tab: "buildings",
        },
        component: () => import("@/views/building/Details.vue"),
    },
    {
        path: "/apartment/:id(\\d+)/:tab(details|authorizations|codes|central-units|subaccounts)?/:page_num?",
        name: "Apartment",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_KEY",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_APP",
                "G_APP_NOM",
                "G_KEY_MOVE",
                "G_NAME_MOVE",
                "G_RESIDENCES",
                "G_KIBOLT",
                "G_TAGIT",
                "G_CONNECTIT",
            ],
            entity: "apartment",
            tab: "residences",
        },
        component: () => import("@/views/apartment/Details.vue"),
    },
    {
        path: "/apartment",
        name: "Apartments",
        meta: {
            layout: "PrivateLayout",
            authorize: [
                "G_KEY",
                "G_KEY_MOBIL",
                "G_CLEPASS",
                "G_FICHES",
                "G_APP",
                "G_APP_NOM",
                "G_KEY_MOVE",
                "G_NAME_MOVE",
                "G_RESIDENCES",
                "G_KIBOLT",
                "G_TAGIT",
                "G_CONNECTIT",
            ],
            entity: "apartment",
            tab: "residences",
        },
        component: () => import("@/components/entities/ApartmentsList.vue"),
    },
    {
        path: "/helpdesk",
        name: "HelpDesk",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/home/Support.vue"),
    },
    {
        path: "/update-module/:search?",
        name: "UpdateModule",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/updateModule/UpdateModule.vue"),
        beforeEnter: (to, from, next) => {
            next()
        },
    },
    {
        path: "/update-module/:id(\\d+)",
        name: "ModuleDetails",
        meta: {
            layout: "PrivateLayout",
            authorize: [],
            tab: "others",
        },
        component: () => import("@/views/updateModule/Details.vue"),
    },
]
